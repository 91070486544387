import * as React from "react"
import {Footer} from "../components/footer";
import {Services2} from "../components/services2";
import {ServiceCloud} from "../components/serviceCloud";
import {Hero2} from "../components/hero2";
import Seo from "../components/seo";
import Layout from "../components/layout";

const IndexPage = () => {
  return (
    <Layout>
      <Seo title={"Innsikt av en annen verden"}/>
      <Hero2 />
      {/*<About />*/}
      {/*<Services />*/}
      <Services2 />
      <ServiceCloud />
      <Footer landing={true} />
    </Layout>
  )
}

export default IndexPage
