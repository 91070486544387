import * as React from "react";
import {graphql, useStaticQuery} from "gatsby";

export const ServiceCloud = () => {
    const {
        data,
    } = useStaticQuery(
        graphql`
            query {
                data: markdownRemark(frontmatter: {slug: {eq: "romvesen-cloud"}}) {
                    id
                    frontmatter {
                        title
                        subTitle
                    }
                    html
                },
            }
        `
    )

    return <div className="relative bg-secondary pt-16 overflow-hidden">
        <div className="mx-auto max-w-md px-4 text-center sm:px-6 sm:max-w-3xl lg:px-8 lg:max-w-7xl">
            <div>
                <h2 className="text-base font-semibold tracking-wider text-primary uppercase">
                    {data.frontmatter.subTitle}
                </h2>
                <p className="mt-2 text-3xl font-extrabold text-gray-100 tracking-tight sm:text-4xl">
                    {data.frontmatter.title}
                </p>
                <div className="mt-5 max-w-prose mx-auto text-xl text-gray-200" dangerouslySetInnerHTML={{ __html: data.html }}>
                </div>
                <div className="mt-6 pb-16">
                    <a href="https://romvesen.cloud"
                       className="inline-flex px-4 py-2 border border-transparent text-secondary font-medium rounded-md shadow-sm text-white bg-primary hover:bg-primary-light">
                        Logg inn ->
                    </a>
                </div>
            </div>
            {/*<div className="mt-12 -mb-4">*/}
            {/*    <GatsbyImage className="rounded-lg shadow-xl ring-1 ring-black ring-opacity-5"*/}
            {/*         image={cloud.childImageSharp.gatsbyImageData}*/}
            {/*         alt="" />*/}
            {/*</div>*/}
        </div>
    </div>
}
