import * as React from "react"
import {graphql, useStaticQuery} from "gatsby"
import Header3 from "./header3";
import {StaticImage} from "gatsby-plugin-image";

export const Hero2 = () => {
    const {
        data,
    } = useStaticQuery(
        graphql`
        query {
            data: markdownRemark(frontmatter: {slug: {eq: "hero"}}) {
                id
                frontmatter {
                    title
                    titleHighlight
                }
            },
        }
    `
    )

    return (<div style={{backgroundColor: '#000'}}>
        <div className="relative">
            <Header3 landing={true} />
            <main>
                <div className="pt-10 sm:pt-16 lg:pt-8 lg:pb-14 lg:overflow-hidden">
                    <div className="mx-auto max-w-7xl lg:px-8">
                        <div className="lg:grid lg:grid-cols-2 lg:gap-8">
                            <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
                                <div className="lg:py-24 text-center">
                                    <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                                        <span className="block">{data.frontmatter.title}</span>
                                        <span className="block text-primary">{data.frontmatter.titleHighlight}</span>
                                    </h1>
                                </div>
                            </div>
                            <div className="lg:m-0 lg:relative">
                                <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0">
                                    <StaticImage placeholder="blurred" quality={100} src="../images/hero.jpg" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    </div>)
}